<template>
  <div
    id="appRoot"
    data-test-id="app"
    class="relative h-full mx-auto"
    :class="{
      'text-vdv-font font-roboto leading-sm md:leading-md':
        $subdomain === 'vdv',
      'text-minilu-font font-roboto leading-sm md:leading-md':
        $subdomain === 'minilu',
    }"
    :app-hydrated="appHydrated"
  >
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <DialogRoot />
    <DebugComponent type="sentry_replay" />
  </div>
</template>

<script setup lang="ts">
import { useAreaStore } from '@/stores/useArea';
import { useNav } from '@/stores/useNav';
import { initCmp } from '@/helpers/cookieManager';
import { callWithNuxt, useRuntimeConfig } from '#app';
import useAppWideMediaQuerySetup from '@/composables/useAppWideMediaQuerySetup';
import { useCachedGet } from '@/composables/dataFetching/genericFetchers';
import type { Result } from './server/api/[site]/core/[...uri].get';
import { infobannerInStorage } from './helpers/infobanner';
import { componentVisibilitySettings as componentVisibilitySettings_IK } from '@/injectionSymbols';
import DialogRoot from '@/components/dialog/dialogRoot.vue';
import { useScrollbarWidth } from './composables/useScrollbarWidth';
import useTheme from '@/composables/useTheme';
import { useSystemContext } from './stores/useSystemContext';
import { useInitStores } from './composables/useInitStores';
import DebugComponent from '@/components/debug/debugComponent.vue';
import { useBannerStore } from './stores/useBannerStore';
import { globalBannerPositions } from './@types/bannerPositions';
import { useUrlActions } from './composables/urlActions/useUrlActions';

await useAppWideMediaQuerySetup();
const appHydrated = ref(false);
const shopIsEnabled = useShopEnabled();
onMounted(() => {
  // Cant watch isHydrating sadly so this will have to do - fails after 2 seconds in the worst case
  checkForHydration();
  if (
    process.env.NODE_ENV === 'production' ||
    Number(useRuntimeConfig().public.DEBUGMODE)
  ) {
    initCmp(
      useSiteIdent() === SiteIdent.default
        ? useRuntimeConfig().public.CMP_VDV_ID
        : useRuntimeConfig().public.CMP_MINILU_ID,
    );
    infobannerInStorage();
  }
  nextTick(() => {
    shopIsEnabled && useUrlActions();
  });
});
useInitStores();
useScrollbarWidth();

useTheme();
const nuxt = useNuxtApp();
const router = useRouter();
const navStore = useNav();
const areaStore = useAreaStore();
const systemContext = useSystemContext();
const bannerStore = useBannerStore();

useRouter().afterEach(async (to) => {
  const uriToLoad = to.path.replace('/', '');
  return callWithNuxt(nuxt, useAfterRouteChangeActions, [uriToLoad]);
});

const uriToLoad = router.currentRoute.value.path.replace('/', '');
// if this fails, the app will not work, so no error catching here
const { data: coreData } = await useCachedGet<Result>(
  `/api/${useSiteIdent()}/core/${
    useRoute().path.replace('/', '') || 'startseite'
  }`,
);
if (
  !coreData.value ||
  typeof coreData.value !== 'object' ||
  coreData.value === null
) {
  throw new Error('ERROR: Daten konnten nicht geladen werden');
}

provide(
  componentVisibilitySettings_IK,
  coreData.value.componentVisibilitySettings,
);
await areaStore.init(coreData.value.areas, coreData.value.defaultArea);
await navStore.init(coreData.value.mainNav);
shopIsEnabled && systemContext.init(coreData.value.context);
useInitTranslations(coreData.value.translations);
bannerStore.registerAssignments(
  coreData.value.bannerAssignments,
  globalBannerPositions,
);

await useAfterRouteChangeActions(uriToLoad);

function checkForHydration(tries = 0) {
  if (useNuxtApp().isHydrating) {
    // eslint-disable-next-line no-console
    if (tries > 20) console.warn('Hydration took longer than 2 Seconds');
    else
      setTimeout(() => {
        checkForHydration(tries + 1);
      }, 100);
  } else {
    appHydrated.value = true;
  }
}

//@TODO Shop -> Extend this
useHead({
  link: [
    {
      id: nuxt.$localeCode as string,
      rel: 'alternate',
      hreflang: nuxt.$localeCode as string,
      href:
        'https://' +
        (useNuxtApp().ssrContext
          ? useNuxtApp().ssrContext.event.node.req.headers.host
          : location.host) +
        useRoute().path,
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `/${nuxt.$subdomain}Favicon.ico`,
    },
  ],
  htmlAttrs: {
    lang: nuxt.$localeCode as string,
  },
});
</script>
<style>
html,
body {
  font-size: 16px;
  height: 100%;
}

#__nuxt {
  height: 100%;
}

html.prevent-scroll {
  overflow: hidden;
  border-right: var(--scrollbar-width, 0px) solid whitesmoke;
}

body {
  background-color: #ffffff;
}

@media (min-width: theme('screens.md')) and (hover: none) {
  html {
    min-width: 1440px;
  }
}

.globalModalOpen {
  overflow: hidden;
}
</style>
