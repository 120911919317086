import { storeToRefs } from 'pinia';
import { UseStateKeys } from '@/useStateKeys';
import StoreIds from './storeIds';
import { useCartStore } from './useCart';
import { useUserContext } from './useUserContext';
import { useUserAddress } from './useUserAddress';
import type { BankData } from '@/components/shop/checkout/payment/types';
import { useReservation } from '@/stores/useReserevation';
import { useSessionStore } from './useSessionStore';

import { v4 as uuidv4 } from 'uuid';
import { PaymentMethodId } from '@/@types/paymentMethodIds';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';

import useSubmitBankData from '~/composables/user/useSubmitBankData';
//import registerVeriFileEvent from '@/components/page/checkout/events/useRegisterVerificationFileReminderEvent'; // Skip feature for first launch
import registerDrugContactAddressChoiceEvent from '@/components/page/checkout/events/useRegisterDrugContactAddressChoiceEvent';
import registerBankDataValidationEvent from '@/components/page/checkout/events/useRegisterBankDataValidationEvent';
import useRegisterCheckBillingAddressOnNewUserEvent from '@/components/page/checkout/events/useRegisterCheckBillingAddressOnNewUserEvent';
import useMailchimpMarketing from '@/composables/checkout/useMailchimpMarketing';
import type { CheckoutStoreStep, HintTypes } from './utils/types';
import useUpdateShippingMethodEvent from '~/components/page/checkout/events/useUpdateShippingMethodEvent';
import { ErrorIdent } from '~/@types/errorIdents';
import useGetDebugData from '@/components/debug/useGetDebugData';
import type { Response as OrderResponse } from '~/server/gateway/connections/shopware/actions/order/create';

export enum CheckoutSteps {
  LOGIN = '/checkout/login',
  ADDRESS = '/checkout/address',
  PAYMENT_SHIPPING = '/checkout/payment-shipping',
  REVIEW = '/checkout/review',
  THANKYOU = '/checkout/thank-you',
}

export const stepOrder = [
  CheckoutSteps.LOGIN,
  CheckoutSteps.ADDRESS,
  CheckoutSteps.PAYMENT_SHIPPING,
  CheckoutSteps.REVIEW,
  CheckoutSteps.THANKYOU,
];

export type OrderExtras = {
  customerComment?: string;
  affiliateCode?: string;
  campaignCode?: string;
};

type CheckoutEvent = {
  id?: string;
  step: CheckoutSteps;
  type: EventType;
  cb: () => Promise<boolean>;
  hasBeenRun?: boolean;
  runEveryTime?: boolean;
};

/**
 * @summary
 * ON_ENTER - Event is fired when the step is entered (after navigation or pageload)
 * ON_NEXT - Event is fired when the step is left, e.g. when going to the next step
 * MANUAL - Event is fired manually via store method
 */
export type EventType = 'ON_ENTER' | 'ON_NEXT' | 'MANUAL';

type CheckoutStoreSteps = {
  [key in CheckoutSteps]: CheckoutStoreStep;
};

/**
 * @Limitations
 * - You should currently not redirect inside the Checkout via Events because you'd redirect in the Event-Context.
 * which means the Event is still running and thus prevents new ON_ENTER events from running. See future thoughts for a possbile solution.
 * There is no usecase for this currently anyway.
 *
 * @FutureThoughts
 * - We could add priority/order-numbers to events and sort them on registration (if we always want specific events to occur befvore others)
 * - We could add a "runAfterSuccess/fail" property to events, so certrain events only run after another certain event was successful/failed
 * - Better redirect handling (cancellation tokens or overrides for event-execution so an event can redirect)
 * */

export const useCheckoutStore = defineStore(StoreIds.CHECKOUT, {
  state: () => ({
    initialized: false,
    isLoading: false,
    eventInProgress: false,
    progressionHalted: false,
    events: [] as CheckoutEvent[],
    hints: {
      showErrors: false,
      showWarnings: false,
      showInfo: false,
    },
    order: {
      customerComment: null as string | null,
      affiliateCode: null as string | null,
      campaignCode: null as string | null,
      isSubmittingOrder: false,
      submitHasFailed: false,
      bankDataSubmitHasFailed: false,
      submitErrors: null as Record<string, any> | null,
      submitResult: null as Record<string, any> | null,
    },
    _meta: {
      debug: null,
    },
  }),
  actions: {
    /**
     * @summary - Initialize the store with all the data needed for the checkout.
     * - Also registers mandatory events for the Checkout.
     * @param refresh - If true, all relevant data will be reloaded from the server. Events will not be registered again
     * @returns true if the initialization was successful, false otherwise
     */
    async init(options?: {
      refresh?: boolean;
      withAfterLoginCartLoad?: boolean;
    }) {
      this._d('Initializing Store', 'INFO');
      if (this._meta.debug === null) {
        this._meta.debug = useGetDebugData()?.value?.store.debug ?? false;
      }
      this.isLoading = true;
      try {
        if (useSessionStore().isLoggedIn) {
          await Promise.all([
            useUserContext().loadUserData(options?.refresh),
            useUserContext().loadAccountData(options?.refresh),
            useUserContext().loadUserContext(options?.refresh),
            useUserAddress().loadAddresses(options?.refresh),
            (async () =>
              options?.withAfterLoginCartLoad
                ? useCartStore().loadCartAfterLogin()
                : useCartStore().loadCart())(),
          ]);
        } else {
          await (async () =>
            options?.withAfterLoginCartLoad
              ? useCartStore().loadCartAfterLogin()
              : useCartStore().loadCart())();
        }
        await useCartStore().eventQueueRunningPromise();
        this._d('Init -> Stores Loaded');

        /**
         * Event Order can matter, always redirect first for example :)
         */
        if (!this.initialized) {
          await useRegisterCheckBillingAddressOnNewUserEvent();
          await Promise.all([
            //registerVeriFileEvent(),
            registerDrugContactAddressChoiceEvent(),
            registerBankDataValidationEvent(),
            useUpdateShippingMethodEvent(),
          ]);
        }
        this._d('Init -> Events registered');

        this.initialized = true;
        this.isLoading = false;
      } catch (e: any) {
        this._d('Init -> Error -> \n' + e, 'ERR');
        useElasticApm()?.captureError({
          ...e,
          message: 'Checkout: Initialization Error | ' + e.message,
        });
        this.isLoading = false;
        return false;
      }
      await redirectIfNeeded(this);
      await _executeEvents(this, this.currentStep, 'ON_ENTER');
      return true;
    },
    /**
     * @summary - Returns an object with methods to manipulate the order state
     * - This is used to keep the store clean and to avoid bloating the store with order related methods on the top level
     * @returns an object with methods to manipulate the order state
     */
    useOrderActions() {
      return {
        setCustomerComment: (comment: string) => {
          this.order.customerComment = comment;
        },
        setAffiliateCode: (code: string) => {
          this.order.affiliateCode = code;
        },
        /**
         * @summary - Reset the order state to its initial state
         * - Does not reset the customerComment and affiliateCode
         */
        resetOrderState: () => {
          this.order.isSubmittingOrder = false;
          this.order.submitHasFailed = false;
          this.order.submitErrors = null;
          this.order.submitResult = null;
          this.order.bankDataSubmitHasFailed = false;
        },
        /**
         * @summary - Submit the order to the server
         * - Also submits the bank data if the selected payment method is debit and the user has not saved bank data yet
         * - Also refreshes the cart/userContext/accountData after a successful order
         * - Adds successful orders to the reservation store
         * @returns true if the order was successful, false otherwise
         */
        submitOrder: async () => {
          {
            this._d('submitOrder -> Start', 'INFO');
            this.order.isSubmittingOrder = true;

            try {
              await _submitBankData();
            } catch (e: any) {
              this._d('submitOrder -> Error -> ' + e, 'ERR');
              useElasticApm()?.captureError({
                ...e,
                message: 'Checkout: Bankdata Submit Failed | ' + e.message,
              });
              this.order.submitErrors = null;
              this.order.bankDataSubmitHasFailed = true;
              this.order.isSubmittingOrder = false;
              return false;
            }

            try {
              let apiUrl = `/api/${useSiteIdent()}/shop/order/create`;
              const cartToken = useCartStore().cart.token;
              const mailchimpMarketing =
                useMailchimpMarketing().value.get(cartToken);
              if (mailchimpMarketing) {
                apiUrl = apiUrl.concat(
                  `?mailchimp_campaign_id=${mailchimpMarketing.mailchimp_campaign_id}`,
                );
              }

              const result = await useLongLoading(
                useSecureSessionPost<OrderResponse>(
                  apiUrl,
                  {
                    customerComment: this.order.customerComment,
                    affiliateCode: this.order.affiliateCode,
                  },
                  { headers: { 'cart-token': cartToken } },
                ),
              );
              if (result && typeof result === 'object') {
                this.order.submitResult = result;
              }

              // Delete the mailchimp marketing data after a successful order because the cart token doesnt change!
              useMailchimpMarketing().value.delete(cartToken);

              const reservationStore = useReservation();
              reservationStore.addReservationForShopOrder({
                typeHandle: 'shop_order_created',
                shopOrder: this.order.submitResult,
              });
              this.order.isSubmittingOrder = false;
            } catch (e: any) {
              this._d('submitOrder -> Error -> ' + e, 'ERR');
              useElasticApm()?.captureError({
                ...e,
                message: 'Checkout: Order Submit Failed | ' + e.message,
              });
              if (e.statusCode === 425) {
                this.order.submitErrors = { errors: [425] };
              } else {
                this.order.submitErrors = e.data?.data ?? null;
              }
              this.order.submitHasFailed = true;
              this.order.isSubmittingOrder = false;
              return false;
            }
            return true;
          }
        },
      };
    },
    /**
     * @summary - Navigate to a specific step in the checkout process
     * - Also handles execution of events (ON_NEXT and ON_ENTER for example)
     * - Handles resume of halted progression
     * @param step - The step to navigate to
     * @returns true if the step is available and navigation was successful, false otherwise
     */
    async goToStep(step: CheckoutSteps) {
      this._d('goToStep -> ' + step, 'INFO');
      if (this.progressionHalted) {
        this.resumeProgression();
        return false;
      }

      if (this.stepStates[step]?.isAvailable) {
        if (step === this.nextStep) {
          await _executeEvents(this, this.currentStep, 'ON_NEXT');
          if (this.progressionHalted) {
            this.resumeProgression();
            return false;
          }
        }
        await navigateTo(step);
        _resetEventsRunCheck(this);
        if (this.currentStep) {
          await redirectIfNeeded(this);
          await _executeEvents(this, this.currentStep, 'ON_ENTER');
        }
        this._d('goToStep -> ' + step + ' -> Success');
        return true;
      } else {
        if (step === this.nextStep) {
          await _executeEvents(this, this.currentStep, 'ON_NEXT');
          if (this.progressionHalted) {
            this.resumeProgression();
            return false;
          }
        }
        if (
          step === CheckoutSteps.THANKYOU &&
          !this.order.submitHasFailed &&
          !this.order.isSubmittingOrder &&
          !this.order.bankDataSubmitHasFailed
        ) {
          await this.useOrderActions().submitOrder();
          await this.goToStep(CheckoutSteps.THANKYOU);
          return true;
        }
        this.showHints(true, 'ERR');
        this.resumeProgression();
        this._d('goToStep -> ' + step + ' -> Failed', 'WARN');
        return false;
      }
    },
    /**
     * @summary - Navigate to the next step in the checkout process
     * - Calls *goToStep()* internally
     */
    async goToNextStep() {
      const currentStepIndex = stepOrder.indexOf(this.currentStep);
      if (
        currentStepIndex === -1 ||
        currentStepIndex === stepOrder.length - 1
      ) {
        return false;
      } else {
        return await this.goToStep(stepOrder[currentStepIndex + 1]);
      }
    },
    /**
     * @summary - Navigate to the previous step in the checkout process
     * - Calls *goToStep()* internally
     */
    async goToPrevStep() {
      const currentStepIndex = stepOrder.indexOf(this.currentStep);
      if (currentStepIndex === -1 || currentStepIndex === 0) {
        return false;
      } else {
        return await this.goToStep(stepOrder[currentStepIndex - 1]);
      }
    },
    /** @summary - Show or hide hints in the checkout */
    async showHints(show: boolean, type: HintTypes) {
      if (type === 'ERR') this.hints.showErrors = show;
      else if (type === 'WARN') this.hints.showWarnings = show;
      else if (type === 'INFO') {
        this.hints.showInfo = show;
      }
    },
    /** @summary - Hides all hints in the checkout */
    resetShowHints() {
      this.hints.showErrors = false;
      this.hints.showWarnings = false;
      this.hints.showInfo = false;
    },
    /**
     * @summary - Register an Event to be run on certain steps in the checkout process
     * Events only run once per step (naviagtion via goToStep resets this), unless the *runEveryTime* flag is set to true
     *
     * @param step - the step to register the event for
     * @param cb async function that returns true if the event was successful, false otherwise
     * @param options.id - optional id for the event, defaults to random uuid
     * @param options.type - optional type for the event, defaults to ON_ENTER
     * @param options.runEveryTime - The event will be triggered again even if it has been executed before, defaults to false
     */
    async registerEvent(
      step: CheckoutSteps,
      cb: () => Promise<boolean>,
      options: {
        id?: string;
        type?: EventType;
        runEveryTime?: boolean;
      } = {},
    ) {
      this._d(`Register Event -> ${step} -> ID: ${options?.id}`);
      if (this.events.some((x) => x.id === options?.id)) {
        this._d(
          'Register Event -> Event with id already exists -> ' + options?.id,
          'WARN',
        );
        return;
      } else {
        this.events.push({
          id: options?.id ?? uuidv4(),
          type: options.type ?? 'ON_ENTER',
          step,
          cb,
          runEveryTime: options.runEveryTime ?? false,
          hasBeenRun: false,
        });
        this._d('Register Event -> Event Registered -> ' + step + options);
      }
    },
    /**
     * @summary - Execute an Event by its id. Can be used to trigger events manually
     * @param id - the id of the event to execute
     */
    async runEventById(id: string) {
      const event = this.events.find((x) => x.id === id);
      if (event) {
        return await _runEvent(this, event);
      }
    },
    /**
     * @summary - Remove an event from the event stack
     * @param id - the id of the event to remove
     */
    removeEventById(id: string) {
      this.events = this.events.filter((x) => x.id !== id);
      this._d('Removed Event -> ID: ' + id);
    },
    /** @summary - Halt the progression of the checkout process (stops navigation and event execution) */
    haltProgression() {
      this.progressionHalted = true;
      this._d('Progression halted');
    },
    /**
     * @summary - Resume the progression of the checkout process
     * - This will NOT re-trigger the iteration of events or navigation, this will just allow them to run again in general
     */
    resumeProgression() {
      this.progressionHalted = false;
      this._d('Progression halted');
    },
    _d(text: string, lvl: 'INFO' | 'WARN' | 'ERR' | 'NONE' = 'NONE') {
      const icon = {
        INFO: '[i]\t',
        WARN: '⚠️\t',
        ERR: '❌\t',
        NONE: '\t',
      };
      if (this._meta.debug)
        // eslint-disable-next-line no-console
        console.debug(`💳\tCheckout-Store: \n${icon[lvl]}${text}`);
    },
    _setDebug(t: boolean) {
      this._meta.debug = t;
    },
  },
  getters: {
    /** @summary - Check if the store is initialized, needed due to queue nature of the cart store */
    storesInitialized(): boolean {
      return (
        (!useSessionStore().isLoggedIn && useCartStore().initialized) ||
        (!!useUserContext().userData &&
          !!useUserContext().accountData &&
          !!useUserContext().userContext &&
          !!useUserAddress().addresses &&
          useCartStore().initialized)
      );
    },
    /** @summary - Get the current step in the checkout process */
    currentStep(): CheckoutSteps | null {
      const router = useRouter();
      const curPath = router.currentRoute.value.path as CheckoutSteps;
      if (Object.values(CheckoutSteps).some((x) => x === curPath)) {
        return curPath as CheckoutSteps;
      } else {
        return null;
      }
    },
    /** @summary - Get the next _available_ step in the checkout process */
    nextAvailableStep(): CheckoutSteps | null {
      for (const step of [...stepOrder].reverse()) {
        if (step === CheckoutSteps.LOGIN || step === CheckoutSteps.THANKYOU)
          continue;
        if (this.stepStates[step].isAvailable) {
          return step;
        }
      }
      return null;
    },
    /** @summary - Get the previous step in the checkout process */
    prevStep(): CheckoutSteps {
      const currentStepIndex = stepOrder.indexOf(this.currentStep);
      if (currentStepIndex === -1 || currentStepIndex === 0) {
        return this.currentStep as unknown as CheckoutSteps;
      } else {
        return stepOrder[currentStepIndex - 1];
      }
    },
    /** @summary - Get the next step in the checkout process*/
    nextStep(): CheckoutSteps {
      const currentStepIndex = stepOrder.indexOf(this.currentStep);
      if (
        currentStepIndex === -1 ||
        currentStepIndex === stepOrder.length - 1
      ) {
        return this.currentStep as unknown as CheckoutSteps;
      } else {
        return stepOrder[currentStepIndex + 1];
      }
    },
    /**
     * @summary - Get the state/availability of the steps in the checkout process
     * - Also adds hints to the steps if they are unavailable
     */
    stepStates(): CheckoutStoreSteps {
      return _addHintsToStepStates(
        _createStepStates(this.storeStates),
        this.storeStates,
      );
    },
    /**@summary - Get the state of the stores in regards to business logic */
    storeStates(): {
      cartOK: boolean;
      userOK: boolean;
      addressOK: boolean;
      shippingMethodOK: boolean;
      paymentMethodOK: boolean;
      orderOK: boolean;
      orderFailed: boolean;
      bankDataFailed: boolean;
      errors: Record<string, any> | null;
    } {
      const {
        isLoading: cartIsLoading,
        cartItemAmount,
        eventQueueRunning,
      } = storeToRefs(useCartStore());
      const { isLoggedIn } = storeToRefs(useSessionStore());
      const { isLoading: userIsLoading, userContext } =
        storeToRefs(useUserContext());
      const { activeShippingAddress, activeBillingAddress } =
        storeToRefs(useUserAddress());

      const paymentMethodOKCheck = () => {
        if (
          PaymentMethodId.DEBIT === userContext.value?.selectedPaymentMethod.id
        ) {
          if (useUserContext().maskedBankData) {
            return true;
          }
          const bankData = useState<BankData>(
            UseStateKeys.BANKDATA_INPUT_CHECKOUT,
          );
          if (!bankData.value || bankData.value?.error?.length) {
            return false;
          } else {
            return true;
          }
        } else {
          return !!userContext.value?.selectedPaymentMethod.id;
        }
      };
      const paymentMethodOK = paymentMethodOKCheck();
      const orderOK = !!this.order.submitResult && !this.order.submitHasFailed;
      const orderFailed = this.order.submitHasFailed;
      const bankDataFailed = this.order.bankDataSubmitHasFailed;
      const errors = this.order.submitErrors;
      const cartOK =
        !cartIsLoading.value &&
        !eventQueueRunning.value &&
        cartItemAmount.value > 0;
      const userOK = !userIsLoading.value && isLoggedIn.value;
      const addressOK =
        !!activeShippingAddress.value && !!activeBillingAddress.value;
      const shippingMethodOK = !!userContext.value?.selectedShippingMethod.id;

      return {
        cartOK,
        userOK,
        addressOK,
        shippingMethodOK,
        paymentMethodOK,
        orderOK,
        orderFailed,
        bankDataFailed,
        errors,
      };
    },
  },
});

/**
 * Add Conditions for Step-Availability here
 */
function _createStepStates(
  storeStates: ReturnType<typeof useCheckoutStore>['storeStates'],
) {
  const {
    cartOK,
    userOK,
    addressOK,
    shippingMethodOK,
    paymentMethodOK,
    orderOK,
  } = storeStates;

  const stepStates = {
    [CheckoutSteps.LOGIN]: { isAvailable: cartOK, hints: [] },
    [CheckoutSteps.ADDRESS]: {
      isAvailable: cartOK && userOK,
      hints: [],
    },
    [CheckoutSteps.PAYMENT_SHIPPING]: {
      isAvailable: cartOK && userOK && addressOK,
      hints: [],
    },
    [CheckoutSteps.REVIEW]: {
      isAvailable:
        cartOK && userOK && addressOK && shippingMethodOK && paymentMethodOK,
      hints: [],
    },
    [CheckoutSteps.THANKYOU]: {
      isAvailable:
        userOK && addressOK && shippingMethodOK && paymentMethodOK && orderOK,
      hints: [],
    },
  } as CheckoutStoreSteps;

  return stepStates;
}

/**
 * Add Messages to StepStates here to display them in the Checkout (under cetain conditions)
 * For example if the next step is unavailable and the user clicks on "next" we can display error messages of the next step
 */
function _addHintsToStepStates(
  checkoutStepsStates: CheckoutStoreSteps,
  storeStates: Parameters<typeof _createStepStates>[0],
) {
  const {
    addressOK,
    shippingMethodOK,
    paymentMethodOK,
    orderFailed,
    bankDataFailed,
    errors,
  } = storeStates;

  if (!addressOK) {
    checkoutStepsStates[CheckoutSteps.ADDRESS].hints.push({
      type: 'ERR',
      description: 'order.submitError.description.address',
    });
  }

  if (!shippingMethodOK) {
    checkoutStepsStates[CheckoutSteps.PAYMENT_SHIPPING].hints.push({
      type: 'ERR',
      description: 'order.submitError.description.shippingMethod',
    });
  }

  if (!paymentMethodOK) {
    checkoutStepsStates[CheckoutSteps.PAYMENT_SHIPPING].hints.push({
      type: 'ERR',
      description: 'order.submitError.description.paymenMethod',
    });
  }

  if (bankDataFailed) {
    checkoutStepsStates[CheckoutSteps.REVIEW].hints.push({
      type: 'ERR',
      description: 'order.submitError.description.general',
      dialog: {
        dialogHeadline: 'order.submitError.headline',
        dialogText: 'order.submitError.bankData',
        dialogType: 'error',
      },
    });
    return checkoutStepsStates;
  }

  if (orderFailed) {
    if (errors?.errors?.includes(ErrorIdent.CART_DRUG_ADDRESS_MISMATCH)) {
      checkoutStepsStates[CheckoutSteps.REVIEW].hints.push({
        type: 'ERR',
        description: 'order.submitError.description.general',
        dialog: {
          dialogHeadline: 'order.submitError.headline',
          dialogText: 'order.submitError.drugAddressMismatch.text',
          dialogType: 'error',
        },
      });
      return checkoutStepsStates;
    }

    if (errors?.errors?.includes(ErrorIdent.CART_PRODUCT_NOT_AVAILABLE)) {
      checkoutStepsStates[CheckoutSteps.REVIEW].hints.push({
        type: 'ERR',
        description: 'order.submitError.description.general',
        dialog: {
          dialogHeadline: 'order.submitError.headline',
          dialogText: 'order.submitError.productNotAvailable.text',
          additionalTextSlots: {
            product: errors?.errorMessages
              ?.find((msg: string) => !!msg)
              ?.match(/The product (.*?) is no longer/)[1],
          },
          dialogType: 'error',
        },
      });
      return checkoutStepsStates;
    }

    let email = 'info@vanderven.de';
    switch (useSiteIdent()) {
      case SiteIdent.default:
        email = 'info@vanderven.de';
        break;
      case SiteIdent.miniluDe:
        email = 'hallo@minilu.de';
        break;
      case SiteIdent.miniluNl:
        email = 'hoi@minilu.nl';
        break;
      case SiteIdent.miniluAt:
        email = 'hallo@minilu.at';
        break;
    }
    checkoutStepsStates[CheckoutSteps.REVIEW].hints.push({
      type: 'ERR',
      description: 'order.submitError.description.general',
      dialog: {
        dialogHeadline: 'order.submitError.headline',
        dialogText: errors?.errors?.includes(425)
          ? 'order.submitError.text.stillprocessing'
          : 'order.submitError.text',
        additionalTextSlots: {
          email,
        },
        dialogType: 'error',
      },
    });
  }
  return checkoutStepsStates;
}

/**
 * @summary - Should not be called directly, only used internally
 * - Submit the bank data if the selected payment method is debit and user has not bankdata saved yet
 * - Uses useState -> BANKDATA_INPUT_CHECKOUT
 */
async function _submitBankData() {
  if (checkIfPaymentMethodIsDebit() && !useUserContext().maskedBankData) {
    await useSubmitBankData(
      useState<BankData>(UseStateKeys.BANKDATA_INPUT_CHECKOUT, () => {
        return {
          bankName: '',
          iban: '',
          bic: '',
          error: ['BANKDATA_INCOMPLETE'],
        };
      }).value,
    );
    return;
  } else {
    return;
  }
}

function checkIfPaymentMethodIsDebit() {
  return (
    PaymentMethodId.DEBIT ===
    useUserContext().userContext?.selectedPaymentMethod.id
  );
}

/**
 * @summary - Should not be called directly, only used internally
 * - Check if there are any events registered for the current step and type, then execute them sequentially
 * - Execution can be halted by using the *haltProgression* method of the store
 * @param store - the CheckoutStore instance
 * @param step - the step to check for
 * @param type - the type to check for
 */
async function _executeEvents(
  store: ReturnType<typeof useCheckoutStore>,
  step: CheckoutSteps,
  type: EventType,
) {
  if (
    store.eventInProgress ||
    store.progressionHalted ||
    (store.currentStep !== step && type === 'ON_ENTER')
  )
    return;

  const events = store.events.filter(
    (x) => x.step === step && x.type === type && !x.hasBeenRun,
  );
  if (events?.length) {
    store.eventInProgress = true;
    const event = events[0];

    await store.runEventById(event.id);
    event.hasBeenRun = true;
    store.eventInProgress = false;

    await _executeEvents(store, step, type);
    if (event.runEveryTime) event.hasBeenRun = false;
  }
}

/**
 * @summary - Should not be called directly, only used internally
 * - Execute an event, returns true if the event was successful, false otherwise
 * @param store - the CheckoutStore instance
 * @param event - the event to execute
 * @returns true if the event was successful, false otherwise
 */
async function _runEvent(
  store: ReturnType<typeof useCheckoutStore>,
  event: CheckoutEvent,
) {
  store._d('Executing Event -> ID: ' + event.id, 'INFO');
  store.eventInProgress = true;
  const success = await event.cb();
  store._d(`Executed Event -> ID: ${event.id} -> Success: ${success}`);
  if (!success) {
    store._d(
      `Executed Event -> ID: ${event.id} -> Success: ${success}`,
      'WARN',
    );
    useElasticApm()?.captureError(
      `Checkout: Event with id ${event.id} of type ${event.type} failed`,
    );
  }
  event.hasBeenRun = true;
  store.eventInProgress = false;
  return success;
}

/**
 * @summary - Should not be called directly, only used internally -
 * - Reset the hasBeenRun flag for all events
 */
async function _resetEventsRunCheck(
  store: ReturnType<typeof useCheckoutStore>,
) {
  store.events.forEach((x) => (x.hasBeenRun = false));
  store._d('Event HasBeenRun-Flag reset');
}

/**
 * @summary Automatic Redirects
 */
async function redirectIfNeeded(store: ReturnType<typeof useCheckoutStore>) {
  store._d('Redirecting if needed');
  //Early return conditions
  if (store.currentStep === CheckoutSteps.THANKYOU) {
    store._d('Redirect not needed', 'NONE');
    return;
  }

  if (useCartStore().itemAmount < 1) {
    store._d('Redirecting to basket', 'NONE');
    return await navigateTo('/checkout/basket');
  } else if (
    store.currentStep === CheckoutSteps.LOGIN &&
    useSessionStore().isLoggedIn
  ) {
    store._d('Redirecting to Address', 'NONE');
    return await store.goToStep(CheckoutSteps.ADDRESS);
  } else if (
    !useSessionStore().isLoggedIn &&
    store.currentStep !== CheckoutSteps.LOGIN
  ) {
    store._d('Redirecting to login', 'NONE');
    return await store.goToStep(CheckoutSteps.LOGIN);
  } else if (!store.stepStates[store.currentStep]?.isAvailable) {
    store._d('Redirecting to next available step', 'NONE');
    return await store.goToStep(store.nextAvailableStep);
  }
  store._d('Redirect not needed', 'NONE');
}
